import { useState } from 'react';
import detectButton from 'core/utils/helpers/detectButton';

export const useIsButtonInstalled = () => {
  const [isButtonInstalled, setIsButtonInstalled] = useState(false);

  detectButton.then((hasButton) => setIsButtonInstalled(hasButton));

  return isButtonInstalled;
};
